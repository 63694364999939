import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Web3 from 'web3'
import abi from '../assets/minConsumerABI.json'
import moment from 'moment'
import { useAccount } from "wagmi";


const CONSUMER_CONTRACT_ADDRESS =
  process.env.REACT_APP_CONSUMER_CONTRACT_ADDRESS
const RPC = process.env.REACT_APP_RPC

const web3 = new Web3(RPC)

const Contract = new web3.eth.Contract(abi, CONSUMER_CONTRACT_ADDRESS)

export const Table = () => {
  const {  isConnected } = useAccount();
  const [data, setData] = useState([])
  const [activeSet, setActiveSet] = useState([])

  const getData = async () => {
    const counter = await Contract.methods.counter().call()
    console.log('Counter is :' + counter)
    let j = 1
    for (let i = counter; i > 0; i--) {
      const contractData = await Contract.methods.getDataOf(i).call()
      console.log('contractData is :', contractData)
      setData((data) => [...data, contractData])
      if (j <= 5) {
        setActiveSet((data) => [...data, contractData])
        j++
      }
    }
  }

  const setActive = (action) => {
    if (action === 'next') {
      const index = data.indexOf(activeSet[activeSet.length - 1])
      setActiveSet([])

      let tempCounter = 0
      for (let i = index + 1; i < data.length; i++) {
        if (tempCounter === 5) {
          break
        }
        setActiveSet((active) => [...active, data[i]])
        tempCounter++
      }
    }
  }

  const formatDate = (timeStamp) => {
    const date = new Date(0)
    date.setUTCSeconds(timeStamp)
    const relativeDate = moment(date).fromNow()
    return relativeDate
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isConnected ? (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Holding Name</th>
            <th>Ticker</th>
            <th>ISIN</th>
            <th>Shares</th>
            <th>Market Value</th>
            <th>% of Net Assets</th>
            <th>Time Stamp</th>
          </tr>
        </thead>
        <tbody>
          {activeSet.map((k) => {
            return (
              <tr key={k[0]}>
                <td>{k[1]}</td>
                <td>{k[2]}</td>
                <td>{k[3]}</td>
                <td>{k[4]}</td>
                <td>{k[5]}</td>
                <td>{k[5]}</td>
                <td>{formatDate(k[7])}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <nav className="pagination" role="navigation" aria-label="pagination">
        <Link
          className="pagination-next"
          onClick={(e) => {
            e.preventDefault()
            setActive('next')
          }}
        >
          Next tab
        </Link>
      </nav>
    </>
  ) : (
    <div className="card-wrapper" style={{ height: '65vh' }}>
      <div className="card">
        <div className="card-content">
          <p className="title">Please connect your wallet</p>
        </div>
      </div>
    </div>
  )
}
