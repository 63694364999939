import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Web3 from 'web3'
import abi from '../assets/minMarketVectorABI.json'
import moment from 'moment'
import { useAccount } from 'wagmi'

const CONSUMER_CONTRACT_ADDRESS =
  process.env.REACT_APP_CONSUMER_CONTRACT_ADDRESS

const RPC = process.env.REACT_APP_RPC
console.log(RPC)

const web3 = new Web3(RPC)

const Contract = new web3.eth.Contract(abi, CONSUMER_CONTRACT_ADDRESS)

export const MVD = () => {
  const { isConnected } = useAccount()
  const { account } = useSelector((state) => state)
  const [dataMVDF, setDataMVDF] = useState([])
  const [dataMVDAMC, setDataMVDAMC] = useState([])

  const requestData = async (val) => {
    const tx = Contract.methods.requestData(
      '0x975aed5fDa3F0F240479987004DdB7D785B69eC7',
      'f00d5bcea9f94c9682491802073bd77b',
      val
    )
    const abi = await tx.encodeABI()
    const gas = web3.utils.toHex(await tx.estimateGas())

    const { ethereum } = window
    await ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: account,
          data: abi,
          gas,
          to: CONSUMER_CONTRACT_ADDRESS,
        },
      ],
    })
  }

  const getData = async (val) => {
    const contractData = await Contract.methods.getDataOf(val).call()
    console.log('contractData is :', contractData)
    if (val === 'MVDF') {
      setDataMVDF(contractData)
      console.log(dataMVDF)
    } else if (val === 'MVDAMC') {
      setDataMVDAMC(contractData)
      console.log(dataMVDAMC)
    }
  }

  useEffect(() => {
    getData('MVDF')
    getData('MVDAMC')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isConnected ? (
    <>
      <div className="notification is-warning is-light">
        After hitting the fetch button, confirm the transaction using metamask
        wallet. Please wait for <strong>10 seconds</strong> after the completion
        of transaction. Then, hit the refresh button to fetch the updated data.
      </div>
      <h2 className="title is-3">Consumer data</h2>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th></th>
              <th>IndexName</th>
              <th>Timestamp</th>
              <th>Value</th>
              <th>Open</th>
              <th>High</th>
              <th>Low</th>
              <th>Change</th>
              <th>PctChange</th>
              <th>Divisor</th>
              <th>Sub</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <button
                type="fetch"
                title="fetch"
                className="button is-info mr-1"
                onClick={(e) => {
                  e.preventDefault()
                  requestData('MVDF')
                }}
              >
                Fetch
              </button>

              <button
                type="refresh"
                title="refresh"
                className="button is-primary"
                onClick={(e) => {
                  e.preventDefault()
                  getData('MVDF')
                }}
              >
                Refresh
              </button>
            </td>
            <td>{dataMVDF.IndexName} </td>
            <td>
              {dataMVDF.TimeStamp &&
                moment(dataMVDF.TimeStamp, 'X').format('YYYY/MM/DD hh:mm:ss A')}
            </td>
            <td>{parseInt(dataMVDF.Value).toFixed(2)}</td>
            <td>{parseInt(dataMVDF.Open).toFixed(2)}</td>
            <td>{parseInt(dataMVDF.High).toFixed(2)}</td>
            <td>{parseInt(dataMVDF.Low).toFixed(2)}</td>
            <td>{parseInt(dataMVDF.Change).toFixed(2)}</td>
            <td>{parseInt(dataMVDF.PctChange).toFixed(2)}</td>
            <td>{parseInt(dataMVDF.Divisor).toFixed(2)}</td>
            <td>{parseInt(dataMVDF.Sub).toFixed(2)}</td>
          </tr>
          <tr>
            <td>
              <button
                title="fetch"
                className="button is-info mr-1"
                onClick={(e) => {
                  e.preventDefault()
                  requestData('MVDAMC')
                }}
              >
                Fetch
              </button>
              <button
                title="refresh"
                className="button is-primary"
                onClick={(e) => {
                  e.preventDefault()
                  getData('MVDAMC')
                }}
              >
                Refresh
              </button>
            </td>
            <td>{dataMVDAMC.IndexName} </td>
            <td>
              {dataMVDAMC.TimeStamp &&
                moment(dataMVDAMC.TimeStamp, 'X').format(
                  'YYYY/MM/DD hh:mm:ss A'
                )}
            </td>
            <td>{parseInt(dataMVDAMC.Value).toFixed(2)}</td>
            <td>{parseInt(dataMVDAMC.Open).toFixed(2)}</td>
            <td>{parseInt(dataMVDAMC.High).toFixed(2)}</td>
            <td>{parseInt(dataMVDAMC.Low).toFixed(2)}</td>
            <td>{parseInt(dataMVDAMC.Change).toFixed(2)}</td>
            <td>{parseInt(dataMVDAMC.PctChange).toFixed(2)}</td>
            <td>{parseInt(dataMVDAMC.Divisor).toFixed(2)}</td>
            <td>{parseInt(dataMVDAMC.Sub).toFixed(2)}</td>
          </tr>
          </tbody>
        </table>
    </>
  ) : (
    <div className="card-wrapper" style={{ height: '65vh' }}>
      <div className="card">
        <div className="card-content">
          <p className="title">Please connect your wallet</p>
        </div>
      </div>
    </div>
  )
}
