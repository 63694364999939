import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Nav } from '../components'
import User from '../assets/images/User.svg'
import balance from '../assets/images/balance.svg'
import { MVD } from '../components/MVD'
// import { Info } from "../components/Info"
import Web3 from 'web3'

import abi from '../assets/minERC20.json'
import { TXN } from '../components/Txn_activity'

const RPC = process.env.REACT_APP_RPC
const web3 = new Web3(RPC)

const LINK_TOKEN_ADDRESS = process.env.REACT_APP_LINK_TOKEN_ADDRESS

export const Dashboard = () => {
  const { oracle_contract, consumer_contract } = useSelector((state) => state)

  const dispatch = useDispatch()

  const getOracleDetails = async () => {
    const Contract = new web3.eth.Contract(abi, LINK_TOKEN_ADDRESS)
    const balance =
      (await Contract.methods.balanceOf(oracle_contract.address).call()) /
      10 ** 18

    dispatch({
      type: 'update_oracle_contract',
      payload: { address: oracle_contract.address, balance },
    })
  }

  const getConsumerDetails = async () => {
    const Contract = new web3.eth.Contract(abi, LINK_TOKEN_ADDRESS)
    const balance =
      (await Contract.methods.balanceOf(consumer_contract.address).call()) /
      10 ** 18

    dispatch({
      type: 'update_consumer_contract',
      payload: { address: consumer_contract.address, balance },
    })
  }

  useEffect(() => {
    getOracleDetails()
    getConsumerDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Nav />
      {/* <Info /> */}
      <div className="wrapper">
        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-content">
                <img src={User} alt="User" />
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <p style={{ fontWeight: 'bold' }}>Oracle Contract</p>
                  <p>
                    <a
                      href="https://testnet.snowtrace.io/address/0x975aed5fDa3F0F240479987004DdB7D785B69eC7"
                      target="_blank"
                      rel='noreferrer'
                    >{`${oracle_contract.address.slice(0, 15)}...`}</a>
                  </p>
                </div>

                <img src={balance} alt="User" />
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <p style={{ fontWeight: 'bold' }}>Balance</p>
                  <p>
                    <a
                      href="https://testnet.snowtrace.io/token/0x0b9d5d9136855f6fec3c0993fee6e9ce8a297846?a=0x975aed5fDa3F0F240479987004DdB7D785B69eC7"
                      target="_blank"
                      rel='noreferrer'
                    >
                      {oracle_contract.balance + ' LINK'}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <div className="card-content">
                <img src={User} alt="User" />
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <p style={{ fontWeight: 'bold' }}>Consumer Contract</p>
                  <p>
                    <a
                      href="https://testnet.snowtrace.io/address/0x9A228211538fB130Fc0177b39ca3B703E8b64f70"
                      target="_blank"
                      rel='noreferrer'
                    >{`${consumer_contract.address.slice(0, 15)}...`}</a>
                  </p>
                </div>

                <img src={balance} alt="User" />
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <p style={{ fontWeight: 'bold' }}>Balance</p>
                  <p>
                    <a
                      href="https://testnet.snowtrace.io/token/0x0b9d5d9136855f6fec3c0993fee6e9ce8a297846?a=0x9A228211538fB130Fc0177b39ca3B703E8b64f70"
                      target="_blank"
                      rel='noreferrer'
                    >
                      {consumer_contract.balance + ' LINK'}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MVD />
        <TXN />
      </div>
    </>
  )
}
