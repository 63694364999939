import company_logo from '../assets/images/new_logo_MVIS.svg'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import '../../src/index.css'

export const Nav = () => {
  return (
    <nav className="navbar">
      <div className="navbar-brand ml-4">
        <img src={company_logo} width="300" height="100" alt='logo'></img>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start"></div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <ConnectButton />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
