import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useAccount } from 'wagmi'
import moment from 'moment/moment'

export const TXN = () => {
  const { isConnected } = useAccount()
  const url =
    'https://api-testnet.snowtrace.io/api?module=account&action=txlist&address=0x9A228211538fB130Fc0177b39ca3B703E8b64f70&startblock=1&endblock=99999999&sort=asc&apikey=YourApiKeyToken'
  const [snowdata, setSnowData] = useState([])

  useEffect(() => {
    axios
      .get(url)
      .then(async (response) => {
        console.log(response.data.result)
        setSnowData(
          response.data.result.sort((a, b) => b.timeStamp - a.timeStamp)
        )
        setSlicedData(response.data.result.slice(0, 10))
      })
      .catch((err) => console.log(err))
  }, [])

  const [slicedData, setSlicedData] = useState([])

  const formatDate = (timeStamp) => {
    const date = new Date(0);
    date.setSeconds(timeStamp)
    return moment(date).fromNow();
  };

  return isConnected ? (
    <>
      <h2 className="title is-3" style={{ marginTop: '50px' }}>
        Transaction activity
      </h2>

      <div style={{ textAlign: "right", marginBottom: "10px" }}>
      <h5 className='title is-5'>
        Latest 10 from a total of {Object.keys(snowdata).length} transactions
      </h5>
      </div>
      <div className='table-container'>
        <table className="table is-fullwidth">
          <th>Txn Hash</th>
          <th>Block Number</th>
          <th>Method Id</th>
          <th>Timestamp</th>
          <th>From</th>
          <th>To</th>
          <th>Value</th>
          <th>Gas used</th>
          <tbody>
          {slicedData.map((data, index) => {
            console.log('data', data)
            return (
                <tr>
                  <td>
                    <a href={`https://testnet.snowtrace.io/tx/${data.hash}`} target='_blank' rel='noreferrer'>
                    {`${data.hash.slice(0,15)}...`}
                    </a>
                  </td>
                  <td>
                    <a href={`https://testnet.snowtrace.io/block/${data.blockNumber}`} target="_blank" rel='noreferrer'>
                    {data.blockNumber}
                    </a>
                    </td>
                  <td>
                    <span className='chip'>{data.methodId}
                    </span>
                    </td>
                  <td>{formatDate(data.timeStamp)}</td>
                  <td>
                  <a href={`https://testnet.snowtrace.io/address/${data.from}`} target="_blank" rel='noreferrer'>
                    {`${data.from.slice(0, 15)}...`}
                    </a>
                  </td>
                  <td>
                  <a href={`https://testnet.snowtrace.io/address/${data.to}`} target="_blank" rel='noreferrer'>{`${data.to.slice(0,15)}...`}</a></td>
                  <td>{data.value + " AVAX"}</td>
                  <td>{data.gasUsed + " μAVAX"}</td>
                </tr>
            )
          })}
          </tbody>
        </table>
        </div>
    </>
  ) : (
    ''
  )
}
