import { createStore } from 'redux'

const ORACLE_CONTRACT_ADDRESS = process.env.REACT_APP_ORACLE_CONTRACT_ADDRESS
const CONSUMER_CONTRACT_ADDRESS =
  process.env.REACT_APP_CONSUMER_CONTRACT_ADDRESS

const initialState = {
  user_token: localStorage.getItem('user_token') || null,
  oracle_contract: {
    address: ORACLE_CONTRACT_ADDRESS,
    balance: 0,
  },
  consumer_contract: {
    address: CONSUMER_CONTRACT_ADDRESS,
    balance: 0,
  },
  account: localStorage.getItem('account') || null,
}

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'update_user_token':
      return (state = { ...state, user_token: action.payload })

    case 'update_oracle_contract':
      return (state = { ...state, oracle_contract: action.payload })

    case 'update_consumer_contract':
      return (state = { ...state, consumer_contract: action.payload })

    case 'update_account':
      return (state = { ...state, account: action.payload })

    default:
      return state
  }
}

export const store = createStore(reducers)
