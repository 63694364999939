import { Routes, Route } from 'react-router-dom'
import { Dashboard } from './pages'

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
    </Routes>
  )
}
