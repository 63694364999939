import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import RainbowKit from "./components/RainbowKit";
import { Provider } from 'react-redux'
import { store } from './store'
import 'bulma/css/bulma.min.css'
import './assets/styles/index.css'
import { App } from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <Provider store={store}>
      <RainbowKit> <App /></RainbowKit>
     
    </Provider>
  </Router>
)
